import React, { useState, useEffect } from "react";

import "./ElevationCard.scss";
import EditButton from "components/Buttons/EditButton";
import CommonModal from "components/Common/CommonModal";
import {
  neutralColorShade,
  primaryColorShade,
  secondaryColorShade,
  supportingColorShade1,
  supportingColorShade2,
} from "assets/data/tokensDefaultData";
import ColorPickerComponent from "components/DesignSystemPage/ColorPickerComponent";
import { EditIcon } from "components/Icons";
import Dropdown from "components/Dropdown";
import { addToLocalStorage } from "hooks/storageUtils";
import { useParams } from "react-router-dom";
import { replaceCharfromKey } from "helpers/token";
import { toPx } from "helpers/transform";
import { removePx } from "helpers/transform";
import AuthAction from "components/auth/AuthAction";
import { useGlobalContext } from "context/GlobalContext";
import { updateCSSVariables } from "helpers/token";
import Permission from "components/auth/Permission";

const ElevationCard = ({ elevationBoxData }) => {
  const { pusherIsLoading, designSystemMeta } = useGlobalContext();
  const maxChar = 20;
  const effects = [
    { name: "Drop Shadow", value: "dropShadow" },
    { name: "Inner Shadow", value: "innerShadow" },
  ];

  const { id } = useParams();
  const [selectedToken, setSelectedToken] = useState({
    value: effects[0].name,
    ...elevationBoxData,
  });
  const [elevationData, setElevationData] = useState(elevationBoxData);
  const [isAddElevationModalOpen, setAddElevationModalOpen] = useState(false);
  const [isColorTokenModalOpen, setColorTokenModalOpen] = useState(false);
  const [buttonState, setButtonState] = useState("");
  const [tokenState, setTokenState] = useState({ key: "", value: "" });
  const [charCount, setCharCount] = useState(0);
  const [elevationColorInfo, setElevationColorInfo] = useState(false);
  useEffect(() => {
    setElevationData(elevationBoxData);
  }, [elevationBoxData, pusherIsLoading]);
  const [colortoken, setColortoken] = useState({
    name: "heading-3xl",
    description: "",
    weight: "Regular",
    size: "16",
    lineHeight: "130%",
    letterSpacing: "0px",
    paragraphSpacing: "15px",
  });
  const colorSections = [
    {
      title: "Primary colors",

      colorData: [
        {
          baseColor: "#473262",
          shades: primaryColorShade,
          tokenType: "primary",
        },
      ],
    },
    {
      title: "Secondary colors",
      colorData: [
        {
          baseColor: "#FF6D3E",
          shades: secondaryColorShade,
          tokenType: "secondary",
        },
      ],
    },
    {
      title: "Supporting colors",
      colorData: [
        {
          baseColor: "#03ADFC",
          shades: supportingColorShade1,
          tokenType: "supporting-color1",
        },
        {
          baseColor: "#FCBA03",
          shades: supportingColorShade2,
          tokenType: "supporting-color2",
        },
      ],
    },

    {
      title: "Neutral colors",

      colorData: [
        {
          baseColor: "#706D69",
          shades: neutralColorShade,
          tokenType: "core.neutral",
        },
      ],
    },
  ];

  const ColorSectionComponent = ({
    title,
    description,
    colorData,
    type,
    setTokenState,
    buttonState,
  }) => (
    <div className='plt-content-wrapper dis-mb-16'>
      {title && (
        <h6 className='plt-contents-head plt-heading-xs plt-font-color-primary dis-mb-12'>
          {title}
        </h6>
      )}
      {description && (
        <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-24'>
          {description}
        </p>
      )}
      {colorData.map((color, index) => (
        <ColorPickerComponent
          key={index}
          buttonState={buttonState}
          setTokenState={setTokenState}
          type={type}
          baseColor={color.baseColor}
          id={id}
          shades={color.shades}
          tokenType={color.tokenType}
        />
      ))}
    </div>
  );

  const handleInputChange = (field, value) => {
    if (field === "description" && value.length > maxChar) {
      return;
    }
    setSelectedToken({ ...selectedToken, [field]: value });
    if (field === "description") {
      setCharCount(value.length);
    }
  };

  const handleNestedInputChange = (field, value) => {
    setSelectedToken((selectedToken) => ({
      ...selectedToken,
      value: {
        ...selectedToken.value,
        [field]: value,
      },
    }));
  };

  const handleApply = () => {
    setAddElevationModalOpen(false);
    const convertedElevation = {
      x: selectedToken.value.x,
      y: selectedToken.value.y,
      blur: selectedToken.value.blur,
      spread: selectedToken.value.spread,
      color: elevationColorInfo.key
        ? elevationColorInfo?.key
        : selectedToken.value.color,
      type: selectedToken?.value?.type?.value, //innerShadow, dropshadow
    };
    const tokenData = [
      { key: selectedToken.key, value: convertedElevation, type_id: 2 },
    ];
    addToLocalStorage(tokenData);
    updateCSSVariables(
      selectedToken?.varName,
      `${toPx(convertedElevation.x)} ${toPx(convertedElevation.y)} ${toPx(
        convertedElevation.blur
      )} ${toPx(convertedElevation.spread)} ${convertedElevation.color}`
    );

    setElevationData((elevationData) => ({
      ...elevationData,
      ...selectedToken,
    }));
  };

  const onApplyChanges = () => {
    handleNestedInputChange("color", tokenState.key);
    handleInputChange("colorValue", tokenState.value);
    setElevationData((elevationData) => ({
      ...elevationData,
      ...selectedToken,
    }));
    setColorTokenModalOpen(false);
  };

  return (
    <div>
      <div className='plt-common-card-box dis-p-24'>
        <h6 className='plt-common-card-title plt-heading-sm plt-font-color-primary dis-mb-4'>
          {elevationData?.name}
          <AuthAction>
            <Permission designSystem={designSystemMeta}>
              <span
                className='w-fit'
                onClick={() => {
                  setAddElevationModalOpen(true);
                  setSelectedToken(elevationData);
                }}
              >
                {" "}
                <EditButton />
              </span>
            </Permission>
          </AuthAction>
        </h6>
        <div className='plt-common-card-box-wrapper d-flex justify-content-between'>
          <div className='plt-common-card-content'>
            <p className='plt-common-card-des plt-body-md dis-mb-24'>
              {elevationData?.content}
              The elevation of a component in its resting/default state.
            </p>

            <h5 className='plt-badge plt-badge plt-badge-surface plt-body-compact-sm dis-mb-8'>
              {elevationData?.className}
            </h5>
            <div className='plt-common-card-axis d-flex'>
              <h5 className='plt-common-card-axis-txt plt-body-compact-sm '>
                X:
                <span className='plt-common-card-axis-value plt-body-compact-sm'>
                  {elevationData?.value?.x}
                </span>
              </h5>
              <h5 className='plt-common-card-axis-txt plt-body-compact-sm '>
                Y:
                <span className='plt-common-card-axis-value plt-body-compact-sm'>
                  {elevationData?.value?.y}
                </span>
              </h5>
              <h5 className='plt-common-card-axis-txt plt-body-compact-sm '>
                Blur:
                <span className='plt-common-card-axis-value plt-body-compact-sm'>
                  {elevationData?.value?.blur}
                </span>
              </h5>
            </div>
          </div>
          <div
            className='plt-common-card-img-box '
            style={{
              boxShadow: `${
                elevationData?.value?.type?.value == "innerShadow"
                  ? "inset"
                  : ""
              } ${toPx(elevationData?.value?.x)} ${toPx(
                elevationData?.value?.y
              )} ${toPx(elevationData?.value?.blur)} ${toPx(
                elevationData?.value?.spread
              )} ${elevationData?.colorValue}`,
            }}
          ></div>
        </div>
      </div>
      <CommonModal
        isOpen={isAddElevationModalOpen}
        onRequestClose={() => setAddElevationModalOpen(false)}
        title='Edit elevation style'
        onApply={handleApply}
        onCancelText='Cancel'
        onApplyText='Apply'
      >
        <div className='plt-modal-body-content'>
          <div className='plt-dropdown-secondary'>
            <div className='plt-select-dropdown-wrapper d-flex align-items-center justify-content-between'>
              {/* <h6 className="plt-select-dropdown-title dis-pr-16 plt-body-compact-sm plt-font-color-primary">
            Effect:
            </h6> */}
              <div className='plt-dropdown-wrapper plt-dropdown-wrapper-shadow'>
                <Dropdown
                  name='type'
                  options={effects}
                  selectedOption={effects.find(
                    (e) => e.value === selectedToken?.value?.type
                  )}
                  onSelect={handleNestedInputChange}
                  value={selectedToken?.value?.type}
                />
              </div>
            </div>
          </div>
          <div className='plt-preview plt-heading-xl dis-mb-32'>
            <div
              className={`plt-common-card-img-box m-auto`} // ${selectedToken.className}
              style={{
                boxShadow: `${
                  selectedToken?.value?.type?.value == "innerShadow"
                    ? "inset"
                    : ""
                } ${toPx(selectedToken?.value?.x)} ${toPx(
                  selectedToken?.value?.y
                )} ${toPx(selectedToken?.value?.blur)} ${toPx(
                  selectedToken?.value?.spread
                )} ${
                  elevationColorInfo?.value
                    ? elevationColorInfo.value
                    : selectedToken?.colorValue
                }`,
              }}
            ></div>
          </div>
          <div className='modal-value-wrapper dis-mb-32'>
            <h6 className='modal-value-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
              Style name
            </h6>
            <div className='modal-value-block w-100'>
              <p className='plt-badge plt-token-value-name plt-body-compact-sm'>
                {selectedToken?.className}
              </p>
            </div>
          </div>
          <div className='elevation-modal-detail d-flex align-items-center justify-content-between dis-mb-32'>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                X
              </h6>
              <div className='modal-value-block w-100'>
                <input
                  name='x'
                  className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                  value={removePx(selectedToken?.value?.x)}
                  onChange={(e) => handleNestedInputChange("x", e.target.value)}
                />
              </div>
            </div>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Y
              </h6>
              <div className='modal-value-block w-100'>
                <input
                  className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                  value={removePx(selectedToken?.value?.y)}
                  onChange={(e) => handleNestedInputChange("y", e.target.value)}
                />
              </div>
            </div>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Blur
              </h6>
              <div className='modal-value-block w-100'>
                <input
                  className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                  value={removePx(selectedToken?.value?.blur)}
                  onChange={(e) =>
                    handleNestedInputChange("blur", e.target.value)
                  }
                />
                <span className='input-suffix plt-body-compact-sm plt-font-color-tertiary'>
                  %
                </span>
              </div>
            </div>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Spread
              </h6>
              <div className='modal-value-block w-100'>
                <input
                  className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                  value={removePx(selectedToken?.value?.spread)}
                  onChange={(e) =>
                    handleNestedInputChange("spread", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className='modal-value-wrapper dis-mb-32'>
            <h6 className='modal-value-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
              Select color
            </h6>
            <div className='plt-token-value-item d-flex'>
              <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12 cursor-pointer'>
                {elevationColorInfo?.key
                  ? replaceCharfromKey(elevationColorInfo.key)
                  : replaceCharfromKey(selectedToken?.value?.color)}
              </p>
              <span
                className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon plt-iconbtn plt-iconbtn-sm'
                onClick={() => setColorTokenModalOpen(true)}
              >
                <EditIcon />
              </span>
            </div>
          </div>
          <div className='modal-textarea-wrapper dis-mb-24'>
            <h6 className='modal-textarea-title plt-ui-text-sm-regular dis-mb-4'>
              Add description
            </h6>
            <textarea
              className='modal-textarea w-100'
              name='description'
              placeholder='Text'
              value={selectedToken.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
            ></textarea>
            <div className='modal-textarea-info d-flex align-items-center justify-content-between dis-mt-4'>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                Helper text
              </span>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                {charCount}/{maxChar}
              </span>
            </div>
          </div>
        </div>
      </CommonModal>
      <CommonModal
        isOpen={isColorTokenModalOpen}
        onRequestClose={() => setColorTokenModalOpen(false)}
        title='Change token'
        onApply={() => onApplyChanges()}
        onCancelText='Cancel'
        onApplyText='Apply'
        variant='change-token-modal'
      >
        <div className='plt-modal-body-content'>
          <div className='dis-pl-24 dis-pr-24'>
            {colorSections.map((section, index) => (
              <div key={section.title}>
                <ColorSectionComponent
                  {...section}
                  // onApplyChanges={onApplyChanges}
                  type={"elevation"}
                  buttonState={buttonState}
                  setTokenState={setElevationColorInfo}
                />
              </div>
            ))}{" "}
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default ElevationCard;
