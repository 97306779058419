import React from "react";
import "./ComponentCard.scss";
import EditButton from "../../Buttons/EditButton";
import CopyButton from "../../Buttons/CopyButton";
import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";
import { useGlobalContext } from "context/GlobalContext";

const ComponentCard = ({
  children,
  code,
  htmlContent = `
<button className="${code}" type="button">
  Primary Button
</button>
`,
  editClick,
  showCode = false,
  edit = false,
}) => {
  const { designSystemMeta } = useGlobalContext();
  const escapedHtmlContent = htmlContent
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;");

  return (
    <div className='component-card'>
      <div className='button-wrapper d-flex justify-content-center'>
        {children}
        {edit && (
          <AuthAction>
            <Permission designSystem={designSystemMeta}>
              <div onClick={editClick} className='component-edit-btn'>
                <EditButton />
              </div>
            </Permission>
          </AuthAction>
        )}
      </div>
      {showCode && (
        <div className='component-details'>
          <h3 className='component-details-title plt-heading-xs font-color-primary dis-mb-8'>
            HTML
          </h3>
          <div className='component-details-wrapper d-flex'>
            <pre>
              <code
                dangerouslySetInnerHTML={{
                  __html: escapedHtmlContent,
                }}
              />
            </pre>
            <div className='component-copy-btn'>
              <CopyButton text={htmlContent} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComponentCard;
