import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "hooks/storageUtils";
import { NameInitialsAvatar } from "react-name-initials-avatar";
import Dropdown from "components/Common/Dropdown/Dropdown";
import { logout } from "helpers/auth";
import { useGlobalContext } from "context/GlobalContext";
import { switchDomain } from "api";
import { isUserAdmin } from "helpers/auth";
import {
  ProfileSettingsIcon,
  HelpCenterIcon,
  ProfileLogoutIcon,
} from "components/Icons";

const UserMenu = ({ UserImage, showDomain = true }) => {
  const { setLoading, currentDomain, setCurrentDomain } = useGlobalContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const user = getUser();
  const isAdmin = isUserAdmin();

  const handleLogout = () => {
    logout(navigate);
  };

  const handleSwitchDomain = async (domainId) => {
    try {
      setLoading(true);
      const { data } = await switchDomain(domainId);
      localStorage.setItem("user", JSON.stringify(data));
      setCurrentDomain(data.current_domain);

      if (process.env.REACT_APP_ENV === "production") {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const newSubdomain = data.current_domain.name;

        const hostParts = url.hostname.split(".");
        hostParts[0] = newSubdomain;
        url.hostname = hostParts.join(".");

        // Get the token from localStorage or any other secure storage
        const token = localStorage.getItem("token");

        // Append the token as a parameter to the URL
        url.searchParams.append("token", token);

        window.location.href = url.href;
      }
    } catch (error) {
      console.error("Failed to switch domain", error);
    } finally {
      setLoading(false);
    }
  };

  const domainList = user?.domains?.map((domain) => (
    <li
      className='dropdown-list-item'
      onClick={() => {
        currentDomain?.id !== domain?.id ? handleSwitchDomain(domain?.id) : "";
        setIsDropdownOpen(false);
      }}
    >
      <div className='domain-list'>
        <div
          className={`dropdown-list-block domain-account d-flex align-items-center ${
            currentDomain?.id !== domain?.id ? "" : "profile-dropdown-active"
          }`}
        >
          {/* <div className='domain-account-img user-info dis-mr-8'>
            <img src={accountOne} alt='user' className='user-image' />
          </div> */}
          <div className='domain-account-info'>
            <span className='plt-body-sm plt-font-color-primary d-block'>
              {domain?.name}
            </span>
            <span className='plt-body-xs plt-font-color-tertiary d-block'>
              {domain?.name}
            </span>
          </div>
        </div>
        {/* <div
          className='dropdown-list-block domain-account d-flex align-items-center'
          key={domain?.id}
          onClick={() => {
            currentDomain.id !== domain.id ? handleSwitchDomain(domain.id) : "";
            setIsDropdownOpen(false);
          }}
        > */}
        {/* <div className='domain-account-img user-info dis-mr-8'>
            <img src={accountTwo} alt='user' className='user-image' />
          </div> */}
        {/* <span className='plt-body-sm plt-font-color-primary d-block'>
            {domain.name} {currentDomain.id !== domain.id ? "" : " * "}
            <span className='plt-body-xs d-block'>sales@aufaitux.com</span>
          </span> */}
        {/* </div> */}
      </div>
    </li>
  ));

  const userAvatar = user.image ? (
    <img src={UserImage} alt='user' className='user-image cursor-pointer' />
  ) : (
    <NameInitialsAvatar
      bgColor='black'
      borderColor='black'
      textColor='white'
      name={user?.name?.toUpperCase()}
    />
  );

  return (
    <Dropdown
      trigger={userAvatar}
      isOpen={isDropdownOpen}
      onToggle={setIsDropdownOpen}
      customClass='user-info-dropdown user-info'
    >
      <ul className='dropdown-list profile-dropdown'>
        <li
          className='dropdown-list-item'
          onClick={() => {
            navigate(`/${user.id}/profile`);
            setIsDropdownOpen(false);
          }}
        >
          <div className='dropdown-list-block profile-details d-flex align-items-center'>
            <span className='profile-details-img user-info dis-mr-8'>
              {userAvatar}
            </span>
            <div className='d-flex flex-column'>
              <span className='plt-heading-xxs plt-font-color-primary'>
                {user?.name}
              </span>
              <span className='plt-body-xs plt-font-color-tertiary'>
                {user?.email}
              </span>
            </div>
          </div>
          <div className='divider'></div>
        </li>
        {isAdmin && (
          <li
            className='dropdown-list-item'
            onClick={() => {
              navigate(`/${user.id}/general`);
              setIsDropdownOpen(false);
            }}
          >
            <div className='dropdown-list-block profile-menu d-flex align-items-center w-100'>
              <span className='dropdown-list-item-icon dis-mr-12'>
                <ProfileSettingsIcon />
              </span>
              <span className='plt-body-sm plt-font-color-primary'>
                Settings
              </span>
            </div>
          </li>
        )}
        <li className='dropdown-list-item'>
          <div className='dropdown-list-block profile-menu d-flex align-items-center w-100'>
            <span className='dropdown-list-item-icon dis-mr-12'>
              <HelpCenterIcon />
            </span>
            <span className='plt-body-sm plt-font-color-primary'>
              Help center
            </span>
          </div>
        </li>
        {showDomain && (
          <>
            <div className='divider dis-mt-8 dis-mb-8'></div>
            <h3 className='domain-title plt-body-xs plt-font-color-tertiary'>
              Switch accounts
            </h3>
            {domainList}
          </>
        )}
        <li
          className='dropdown-list-item'
          onClick={() => {
            handleLogout();
            setIsDropdownOpen(false);
          }}
        >
          <div className='divider dis-mt-8 dis-mb-8'></div>

          <div className='dropdown-list-block profile-menu d-flex align-items-center w-100'>
            <span className='dropdown-list-item-icon dis-mr-12'>
              <ProfileLogoutIcon />
            </span>
            <span className='plt-body-sm plt-font-color-primary'>Logout</span>
          </div>
        </li>
      </ul>
    </Dropdown>
  );
};

export default UserMenu;
