import React, { useEffect, useState } from "react";
import { EyeButton, EyeButtonOpen, ErrorIcon } from "../Icons/index";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchInvite, inviteRegister, register } from "../../api";
import "./SignUpForm.scss";
import { SyncLoader } from "react-spinners";
import { useGlobalContext } from "context/GlobalContext";

const InviteSignUpForm = () => {
  const navigate = useNavigate();
  const { invite_token } = useParams();
  const { setLoading } = useGlobalContext();

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fullNameError, setFullNameError] = useState("");

  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  useEffect(() => {
    const getInvite = async (token) => {
      try {
        setLoading(true);
        const { data } = await fetchInvite(token);
        if (data) {
          setEmail(data.email);
        }
      } catch (error) {
        console.error("Failed to fetch invite", error);
      } finally {
        setLoading(false);
      }
    };
    getInvite(invite_token);
  }, []);

  const validateFullName = (value) => {
    if (!value || value.length < 3) {
      setFullNameError("Please enter a valid name with at least 3 characters.");
    } else {
      setFullNameError("");
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || !emailRegex.test(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);
    validateFullName(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const validateForm = (e) => {
    e.preventDefault();

    let valid = true;

    if (!email || !email.includes("@")) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!fullName || fullName.length < 2) {
      setFullNameError("Please enter a valid Name with min 3 char.");
      valid = false;
    } else {
      setFullNameError("");
    }

    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let formIsValid = true;
    if (!password) {
      setPasswordError(true);
      formIsValid = false;
    }
    if (!confirmedPassword) {
      setConfirmPasswordError(true);
      formIsValid = false;
    }
    if (password !== confirmedPassword) {
      setConfirmPasswordError(true);
      formIsValid = false;
    }
    if (!formIsValid) {
      setIsLoading(false);

      return;
    }

    try {
      const response = await inviteRegister(
        fullName,
        email,
        password,
        password,
        invite_token
      );
      toast.success(response.message, {
        onClose: () => navigate("/login"),
        autoClose: 1000,
      });
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message || "An unexpected error occurred";
      toast.error(errorMessage);
    }
    setIsLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Check if password meets conditions
    if (
      !newPassword ||
      newPassword.length < 6 ||
      !/[a-z]/.test(newPassword) ||
      !/[A-Z]/.test(newPassword) ||
      !/\d/.test(newPassword) ||
      !/[!@#$%^&*]/.test(newPassword)
    ) {
      // alert("Password");
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmedPassword = e.target.value;
    setConfirmedPassword(newConfirmedPassword);

    // Check if confirm password matches password
    if (newConfirmedPassword !== password) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
  };

  return (
    <AuthLayout>
      <div className=' register-container flex flex-column  w-full h-full '>
        <ToastContainer />
        <form
          className='flex flex-column dis-mt-10  '
          onSubmit={validateForm}
          action='#'
        >
          <div className='w-full'>
            <h4 className='plt-heading-lg plt-font-color-primary dis-px-auto'>
              Create your account
            </h4>
            <p className='info-msg  plt-body-md plt-font-color-secondary dis-mt-12'>
              Enter your details below and create your account and get started
            </p>
            <div className='label-input-wrapper dis-mb-24'>
              <label
                htmlFor='fullName'
                className=' input-label plt-font-color-primary plt-ui-text-md-medium font-weight-500'
              >
                Full name
              </label>
              <input
                type='text'
                id='fullName'
                autoFocus={true}
                tabIndex={1}
                placeholder='Enter your full name'
                value={fullName}
                onChange={handleFullNameChange}
                className={`input-field-custom w-full ${
                  emailError ? "border-red-500" : ""
                }`}
              />
              {fullNameError && (
                <p className='error-msg plt-body-xs dis-mt-4'>
                  {fullNameError}
                </p>
              )}
            </div>
            <div className='label-input-wrapper  dis-mb-32'>
              <label
                htmlFor='email'
                className='input-label plt-font-color-primary plt-ui-text-md-medium font-weight-500'
              >
                Email address
              </label>
              <input
                type='email'
                id='email'
                readOnly={true}
                placeholder='Enter your email'
                tabIndex={3}
                value={email}
                onChange={handleEmailChange}
                className={`input-field-custom w-full ${
                  emailError ? "border-red-500" : ""
                }`}
              />
              {emailError && (
                <p className='error-msg plt-body-xs dis-mt-4'>{emailError}</p>
              )}
            </div>
          </div>

          <div className='w-full'>
            <div className='label-input-wrapper'>
              <label
                htmlFor='password'
                className='input-label plt-ui-text-md-medium plt-font-color-primary'
              >
                Password
              </label>
              <div className='relative'>
                <input
                  type={showPassword ? "text" : "password"}
                  id='password'
                  placeholder='Password'
                  tabIndex={5}
                  autoFocus={true}
                  value={password}
                  onChange={handlePasswordChange}
                  className={`input-field-custom relative w-full ${
                    passwordError ? "border-red-500" : ""
                  }`}
                />
                <span
                  onClick={togglePasswordVisibility}
                  className='absolute eye-button'
                >
                  {showPassword ? <EyeButton /> : <EyeButtonOpen />}
                </span>
              </div>

              <p
                className={`${
                  passwordError ? "error-msg" : "text-[#BCBCBC]"
                }  plt-body-xs d-flex`}
              >
                {passwordError && (
                  <span className='plt-error-icon dis-mr-4 dis-mt-4'>
                    <ErrorIcon />
                  </span>
                )}
                <span
                  className={`${
                    passwordError ? "error-msg" : "text-[#BCBCBC]"
                  }  error-msg-change plt-body-xs dis-mt-4`}
                >
                  Minimum of 6 characters, with upper and lowercase and a number
                  and symbol
                </span>
              </p>
            </div>
            <div className='label-input-wrapper dis-mt-24'>
              <label
                htmlFor='confirmPassword'
                className='input-label plt-body-md plt-font-color-primary'
              >
                Confirm password
              </label>
              <input
                type='password'
                id='confirmPassword'
                placeholder='Confirm Password'
                tabIndex={6}
                value={confirmedPassword}
                onChange={handleConfirmPasswordChange}
                className={`input-field-custom w-full ${
                  confirmPasswordError ? "border-red-500" : ""
                }`}
              />
              {/* <p
                  className={`${
                    confirmPasswordError ? "error-msg" : "text-red-[#BCBCBC]"
                  }  plt-body-xs error-msg`}
                >
                  {confirmPasswordError && <span>x</span>}
                  {confirmPasswordError ? "Passwords do not match" : ""}
                </p> */}

              <p
                className={`${
                  confirmPasswordError ? "error-msg" : "text-red-[#BCBCBC]"
                }  plt-body-xs d-flex`}
              >
                {confirmPasswordError && (
                  <>
                    <span className='plt-error-icon dis-mr-4 dis-mt-4'>
                      <ErrorIcon />
                    </span>
                    <span
                      className={`${
                        confirmPasswordError ? "error-msg" : "text-[#BCBCBC]"
                      }  error-msg-change plt-body-xs dis-mt-4`}
                    >
                      Password doesn't match
                    </span>
                  </>
                )}
              </p>
            </div>
            <button
              onClick={handleSubmit}
              type='submit'
              tabIndex={6}
              className='primary-button-custom plt-ui-text-lg-medium plt-btn plt-btn-primary dis-mt-32'
            >
              {isLoading ? (
                <SyncLoader
                  color='#fff'
                  loading={true}
                  width='100%'
                  height='100%'
                />
              ) : (
                "Sign up"
              )}
            </button>
          </div>

          <div className='d-flex w-full dis-mt-24  align-items-center justify-content-center'>
            <span className='text-desc plt-body-compact-sm font-weight-500'>
              Already have an account?
            </span>
            <Link
              to='/login'
              className='text-link dis-ml-8 plt-body-compact-sm font-weight-500'
            >
              Log in.
            </Link>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default InviteSignUpForm;
