import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./SideNavTabs.scss";
import { useGlobalContext } from "context/GlobalContext";
import EditButton from "components/Buttons/EditButton";
import AuthAction from "components/auth/AuthAction";
import { toast } from "react-toastify";
import ThumbnailModal from "components/Home/FileUpload/ThumbnailModal";
import { changeThumbnail } from "api";
import AddButton from "components/Buttons/AddButton";
import AddPageModal from "components/modal/AddPageModal";
import { createPage } from "api";
import Tooltip from "components/Tooltip";
import { deleteDesignSystemImage } from "api";
import { DeleteButtonicon } from "components/Icons";
import { CardSettingsIcon } from "components/Icons";
import { ThreeDotIcon } from "components/Icons";
import RenamePageModal from "components/modal/RenamePageModal";
import { renamePage } from "api";
import { deletePage } from "api";
import ConfirmDialog from "components/Common/Confirm/ConfirmDialog";
import Permission from "components/auth/Permission";

const SideNavTabs = ({ logo, title, tabs }) => {
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsEditDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setIsPageDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef2]);

  const location = useLocation();
  const { id, pageId } = useParams();
  const {
    isCollapsed,
    setIsCollapsed,
    setLoading,
    designSystemMeta,
    designSystems,
    setDesignSystems,
    setDesignSystemMeta,
  } = useGlobalContext();
  const [showEditButton, setShowEditButton] = useState(false);
  // const [showPageButton, setShowPageButton] = useState(false);
  const [isThumbnailModalOpen, setThumbnailModalOpen] = useState(false);
  const [isAddPageModalOpen, setAddPageModalOpen] = useState(false);
  const [error, setError] = useState();
  const [isEditDropdownOpen, setIsEditDropdownOpen] = useState(false);
  const [isPageDropdownOpen, setIsPageDropdownOpen] = useState(false);
  const [isRenamePageModalOpen, setRenamePageModalOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState("");
  const [clickIndex, setClickIndex] = useState(0);

  const handleMouseEnter = () => {
    setShowEditButton(true);
  };

  const handleMouseLeave = () => {
    setShowEditButton(false);
  };
  const handleClick = () => {
    setIsEditDropdownOpen((prev) => !prev);
  };

  //Pages threedot menu
  // const handlePageMouseEnter = () => {
  //   setShowPageButton(true);
  // };

  const handlePageMouseLeave = () => {
    setIsPageDropdownOpen(false);
  };
  const handlePageClick = () => {
    setIsPageDropdownOpen((prev) => !prev);
  };
  const closeThumbnailModal = () => {
    setThumbnailModalOpen(false);
  };

  // Add page
  const openAddPageModal = () => {
    setAddPageModalOpen(true);
  };

  const closeAddPageModal = () => {
    setAddPageModalOpen(false);
  };
  // Rename page
  const openRenamePageModal = () => {
    setRenamePageModalOpen(true);
  };

  const closeRenamePageModal = () => {
    setRenamePageModalOpen(false);
  };

  const handleAddPageApply = async (name) => {
    let type = "";
    if (title == "Overview") {
      type = "overview";
    } else if (title == "Content") {
      type = "content";
    } else if (title == "Resources") {
      type = "resources";
    }
    const dataToSubmit = {
      name,
      content: "",
      type: type,
      design_system_id: id,
    };

    try {
      setLoading(true);
      const data = await createPage(dataToSubmit);
      setDesignSystemMeta((prevState) => ({
        ...prevState,
        pages: [...prevState.pages, data.data],
      }));
    } catch (error) {
      console.error("Failed to create page:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleThumbnailApply = async (file) => {
    if (!file) {
      setError("Please select an image");
      return;
    }
    try {
      const request = {
        image: file,
        admin_id: designSystemMeta.admin_id,
        design_system_id: designSystemMeta.id,
        type: "logo",
      };
      setLoading(true);
      const data = await changeThumbnail(designSystemMeta.id, request);
      toast.success(data.message, {
        onClose: () => {},
        autoClose: 1000,
      });
      updateStateWithThumbnail(data.data, "logo");
    } catch (error) {
      console.error("Failed to change thumbnail", error);
      let errorMessage = "";
      errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message || "An unexpected error occurred";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
    closeThumbnailModal();
  };

  const updateStateWithThumbnail = (data, type) => {
    const newDesignSystemMeta = { ...designSystemMeta };
    newDesignSystemMeta.logo = data.logo;
    newDesignSystemMeta.logo_url = data.logo_url;
    setDesignSystemMeta(newDesignSystemMeta);

    const newDesignSystems = [...designSystems];
    const obj = newDesignSystems.find((ds) => ds.id === designSystemMeta.id);
    obj.logo = data.logo;
    obj.logo_url = data.logo_url;
    setDesignSystems(newDesignSystems);
  };

  const handleDeleteLogo = async () => {
    const type = "logo";
    setIsEditDropdownOpen(false);
    //   if (confirm("Are you sure to delete the logo") == true) {
    try {
      setLoading(true);
      const data = await deleteDesignSystemImage(designSystemMeta.id, type);
      updateStateWithThumbnail(data.data, type);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed delete image", error);
    } finally {
      setLoading(false);
      closeDeleteLogoModal();
    }
    // }
  };

  const showContextMenu = (data, index) => {
    setIsPageDropdownOpen((prev) => !prev);
    setSelectedPage(data);
    setClickIndex(index);
  };

  const handleRenamePageApply = async (id, name) => {
    if (id && name) {
      try {
        setLoading(true);
        const data = await renamePage(id, name);
        updateState(data.data);
      } catch (error) {
        console.error("Failed to rename page:", error.message);
      } finally {
        setLoading(false);
        setRenamePageModalOpen(false);
        setIsPageDropdownOpen((prev) => !prev);
      }
    }
  };

  const handleDeletePage = async () => {
    setIsEditDropdownOpen(false);
    if (confirm("Are you sure to delete") == true) {
      try {
        setLoading(true);
        const data = await deletePage(selectedPage.id);
        removeState(selectedPage.id);
        toast.success(data.message);
      } catch (error) {
        toast.error(error.message);
        console.error("Failed delete page", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const updateState = (data) => {
    const newDesignSystemMeta = { ...designSystemMeta };
    const newPages = newDesignSystemMeta.pages.map((p) => {
      if (p.id !== data.id) {
        return p;
      } else {
        return {
          ...p,
          name: data.name,
        };
      }
    });
    newDesignSystemMeta.pages = newPages;
    setDesignSystemMeta(newDesignSystemMeta);
  };

  const removeState = (pageId) => {
    const newDesignSystemMeta = { ...designSystemMeta };
    const newPages = newDesignSystemMeta.pages.filter((p) => p.id !== pageId);
    newDesignSystemMeta.pages = newPages;
    setDesignSystemMeta(newDesignSystemMeta);
  };

  const [isDeleteLogoModalOpen, setDeleteLogoModalOpen] = useState(false);
  const closeDeleteLogoModal = () => {
    setDeleteLogoModalOpen(false);
  };

  return (
    <div className={`tabs-container ${isCollapsed ? "active" : ""}`}>
      <div className='side-tabs'>
        <div
          className='logo d-flex'
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
        >
          <Link
            style={{ textDecoration: "none" }}
            to='/'
            className='d-flex align-items-center w-100'
          >
            <span className='logo-icon'>{logo.component}</span>
            {/* <h2 className='logo-text'>{logo.text}</h2> */}
          </Link>
          {title !== "Profile settings" && (
            <AuthAction>
              <Permission designSystem={designSystemMeta}>
                {showEditButton && (
                  <div onClick={handleClick} className='logo-edit-icon'>
                    <EditButton />
                  </div>
                )}
              </Permission>
            </AuthAction>
          )}
          {isEditDropdownOpen && (
            <div className='user-dropdown' ref={dropdownRef}>
              <ul>
                <li
                  className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                  onClick={() => setThumbnailModalOpen(true)}
                >
                  Change logo
                </li>
                <li
                  className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                  onClick={() => {
                    setDeleteLogoModalOpen(true);
                  }}
                >
                  <span className='dropdown-item-icon dis-mr-8'>
                    Delete logo
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
        <h4 className='tabs-title plt-body-compact-xs dis-mb-8 dis-mt-24 d-flex align-items-center justify-content-between'>
          {title}
          {location.pathname === `/${id}/overview/getting-started` ||
          location.pathname === `/${id}/overview/pages/${pageId}` ||
          location.pathname.includes("/content") ||
          location.pathname.includes("/resources") ? (
            <AuthAction>
              <Permission designSystem={designSystemMeta}>
                <span className='add-page-btn' onClick={openAddPageModal}>
                  <Tooltip position='top' arrow='btm-center' text='Add section'>
                    <AddButton />
                  </Tooltip>
                </span>
              </Permission>
            </AuthAction>
          ) : (
            ""
          )}
        </h4>
        {tabs.map((tab, index) => {
          // const isDisabled = false;
          return tab?.disabled ? (
            <h4 className='tabs-title plt-body-compact-xs dis-mb-8 dis-mt-16'>
              {tab.title}
            </h4>
          ) : (
            <>
              <div
                // onMouseOver={handlePageMouseEnter}
                // onMouseOut={handlePageMouseLeave}

                // onClick={handlePageClick}
                className='newpage-link d-flex align-items-center justify-content-between'
                key={index}
              >
                <Link
                  key={index}
                  style={{ textDecoration: "none" }}
                  to={tab.link}
                  className={`tabs-link ${
                    location.pathname.includes(tab.link) ? "active" : ""
                  }`}
                >
                  <h3
                    className={`tabs plt-body-compact-sm dis-mt-8 dis-mb-8 ${
                      location.pathname === tab.link ? "active-text" : ""
                    }`}
                  >
                    {tab.title}
                  </h3>
                </Link>

                {(tab.link.includes(`/${id}/overview/pages`) ||
                  tab.link.includes(`/content`) ||
                  tab.link.includes(`/resources`)) && (
                  <AuthAction>
                    <Permission designSystem={designSystemMeta}>
                      <div
                        className='newpage-link-setting'
                        onClick={() => showContextMenu(tab?.page, index)}
                      >
                        <ThreeDotIcon />
                      </div>
                    </Permission>
                  </AuthAction>
                  //   <div className="newpage-setting">
                  //   <ThreeDotIcon />
                  // </div>
                )}

                {isPageDropdownOpen && index === clickIndex && (
                  <div className='user-dropdown' ref={dropdownRef2}>
                    <ul>
                      <li
                        className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                        onClick={() => setRenamePageModalOpen(true)}
                      >
                        Rename Page
                      </li>
                      <li
                        className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                        onClick={handleDeletePage}
                      >
                        <span className='dropdown-item-icon dis-mr-8'>
                          Delete Page
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
      <ThumbnailModal
        isOpen={isThumbnailModalOpen}
        onRequestClose={closeThumbnailModal}
        onApply={handleThumbnailApply}
        title={"Change logo"}
        data={designSystemMeta}
        error={error}
        aspectRatio={132 / 46}
      />
      <AddPageModal
        isOpen={isAddPageModalOpen}
        onRequestClose={closeAddPageModal}
        onApply={handleAddPageApply}
        title={"Add section"}
      />
      <RenamePageModal
        isOpen={isRenamePageModalOpen}
        onRequestClose={closeRenamePageModal}
        onApply={handleRenamePageApply}
        title={"Rename"}
        data={selectedPage}
      />
      <ConfirmDialog
        footerClassName='dss'
        isOpen={isDeleteLogoModalOpen}
        onRequestClose={closeDeleteLogoModal}
        onApply={handleDeleteLogo}
        title='Remove this logo?'
        message='The logo you have uploaded will be removed.'
      />
    </div>
  );
};

export default SideNavTabs;
