import React, { useState } from "react";

import "./Character.scss";
import CharacterBox from "./CharacterBox";
import EditHeadingModal from "components/modal/EditHeadingModal";
import EditButton from "components/Buttons/EditButton";
import TabNavigation from "components/DesignSystemPage/TabNavigation/index";
import useScrollManagement from "hooks/useScrollManagement";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import { getValueByPath } from "helpers/token";
import { addToLocalStorage } from "hooks/storageUtils";
import { updateValueByPath } from "helpers/token";
import AddButton from "components/Buttons/AddButton";
import AddFontModal from "components/modal/AddFontModal";
import { createFont, uploadFont } from "api";
import { toast } from "react-toastify";
import { loadGoogleFont, loadManualFont } from "helpers/font";
import UploadFontModal from "components/modal/UploadFont";
import UploadButton from "components/Buttons/UploadButton";
import Tooltip from "components/Tooltip";
import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";

const Character = ({ setSelectedTab }) => {
  const { id } = useParams();
  const { designSystem, setLoading, designSystemMeta } = useGlobalContext();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isAddFontModalOpen, setAddFontModalOpen] = useState(false);
  const [isUploadFontModalOpen, setUploadFontModalOpen] = useState(false);
  const [activeSideTab, setActiveSideTab] = useState(0);
  const [selectedToken, setSelectedToken] = useState({});

  const tabData = ["Typeface headings", "Typeface Body"];
  const fontTypes = [
    { key: "core.fontFamily.primary", name: "primary" },
    { key: "core.fontFamily.secondary", name: "secondary" },
  ];
  const fontDatas = fontTypes?.map((type) =>
    getValueByPath(designSystem, type?.key)
  );

  const primaryData = fontDatas?.find(
    (font) => font?.key === "core.fontFamily.primary"
  );
  const secondaryData = fontDatas?.find(
    (font) => font?.key === "core.fontFamily.secondary"
  );

  const TypographyCharacterDatas = [
    {
      id: 1,
      className: "dss-character-title dss-heading-2xl dss-font-color-primary",
      name: primaryData?.value,
      content: "See all ",
      content2: "for variations and usages.",
      nested_desc: "type scales",
      title: "Typeface headings",
      description:
        "Largest heading; commands attention, creates emphasis, and establishes a memorable visual signature.",
      font_family: "dss-font-family-primary",
      key: "core.fontFamily.primary",
    },
    {
      id: 2,
      className:
        "plt-character-title dss-body-strong-sm-semi-bold  dss-font-color-primary",
      name: secondaryData?.value,
      content: "See all ",
      content2: "for variations and usages.",
      nested_desc: "type scales",
      title: "Typeface Body",
      description:
        "Body text is used for the majority of written content. It should be highly legible for prolonged reading.",
      font_family: "dss-font-family-secondary",
      key: "core.fontFamily.secondary",
    },
  ];

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  //Add font modal
  const openAddFontModal = () => {
    setAddFontModalOpen(true);
  };

  const closeAddFontModal = () => {
    setAddFontModalOpen(false);
  };
  const closeUploadFontModal = () => {
    setUploadFontModalOpen(false);
  };

  const handleFontAddFontApply = async (font) => {
    try {
      setLoading(true);
      const input = { ...font, design_system_id: id };
      const data = await createFont(input);
      designSystemMeta.fonts = [...designSystemMeta.fonts, data.data];
      // loadGoogleFont(font.family);
      // toast.success(data.message);
    } catch (error) {
      // toast.error(error.message);
      console.error("Failed to add font", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadFontApply = async (font) => {
    try {
      setLoading(true);
      const input = { ...font, design_system_id: id };
      const data = await uploadFont(id, input);
      designSystemMeta.fonts = [...designSystemMeta.fonts, data.data];

      loadManualFont(data.data);

      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to upload font", error);
    } finally {
      setLoading(false);
    }
  };
  const sectionRefs = TypographyCharacterDatas?.map(() => React.useRef(null));

  const scrollToSection = useScrollManagement(
    sectionRefs,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index, sectionRefs);
  };
  const handleApply = async (font) => {
    //Insert font to database
    let data = {
      family: font,
      variant: "400",
      url: "url",
      type: "google",
    };

    //Check font already exist
    const isNewFont = designSystemMeta.fonts.find(
      (font) => font.family === data.family
    );
    if (!isNewFont) {
      handleFontAddFontApply(data);
    }

    // Update token
    const tokenData = [{ key: selectedToken?.key, value: font }];
    addToLocalStorage(tokenData);
    updateValueByPath(designSystem, selectedToken.key, font);
    setSelectedToken({ ...selectedToken, name: font });
  };
  return (
    <div className='d-flex justify-content-between w-full'>
      <div className='tab-content-container dis-pr-48 dis-pl-48'>
        {TypographyCharacterDatas?.map((data, index) => (
          <div
            key={data?.id}
            ref={sectionRefs[index]}
            className='plt-content-block dis-mb-48'
          >
            {/* {index === 0 && (
              <AuthAction>
                <div
                  onClick={() => setUploadFontModalOpen(true)}
                  className='d-flex justify-content-between dis-mb-16 w-fit align-items-center font-upload plt-function-icon '
                >
                  <p className='dis-mr-8  plt-font-color-primary '>
                    Upload Font
                  </p>
                  <span className='plt-function-icon d-flex'>
                    <Tooltip
                      position='top'
                      arrow='btm-center'
                      text='Upload font'
                    >
                      <UploadButton />
                    </Tooltip>
                  </span>
                </div>
              </AuthAction>
            )} */}
            <div className='plt-content-wrapper dis-mb-24'>
              <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
                <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
                  {data.title}
                </h6>
                <AuthAction>
                  <Permission designSystem={designSystemMeta}>
                    <span
                      onClick={() => {
                        setModalOpen(true);
                        setSelectedToken(data);
                      }}
                      className='plt-function-icon dis-ml-8'
                    >
                      <Tooltip
                        position='top'
                        arrow='btm-center'
                        text='Change font'
                      >
                        <EditButton />
                      </Tooltip>
                    </span>
                  </Permission>
                </AuthAction>
              </div>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {data.description}
              </p>
            </div>
            <CharacterBox
              className={data.className}
              setSelectedTab={setSelectedTab}
              characterBoxData={data}
            />
          </div>
        ))}
        <EditHeadingModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          onUpload={setUploadFontModalOpen}
          onApply={handleApply}
          title='Change font'
          initialFont={selectedToken.name}
          fonts={designSystemMeta?.fonts}
        />
      </div>
      <div className='plt-token-contents'>
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
      <AddFontModal
        isOpen={isAddFontModalOpen}
        onRequestClose={closeAddFontModal}
        onApply={handleFontAddFontApply}
        title='Add Google font'
      />
      <UploadFontModal
        isOpen={isUploadFontModalOpen}
        onRequestClose={closeUploadFontModal}
        onApply={handleUploadFontApply}
        title='Upload font'
      />
    </div>
  );
};

export default Character;
