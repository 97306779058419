import React, { useState, useRef, useEffect } from "react";
import SettingsLayout from "components/layouts/SettingsLayout";
import DesignsystemsTable from "./DesignsystemsTable";
import { useGlobalContext } from "context/GlobalContext";
import { fetchRoles } from "api";
import { FilterButtonicon, PlusButtonWhiteicon } from "components/Icons";
import "./Designsystems.scss";
import Dropdown from "components/Common/Dropdown/Dropdown";
import { fetchSettingsDesignSystems } from "api";
import { getAdminId } from "hooks/storageUtils";

const Designsystems = () => {
  const [designSystems, setDesignSystems] = useState([]);

  const { setLoading } = useGlobalContext();

  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const getDesignSystems = async (userId) => {
      try {
        setLoading(true);
        const stateToken = localStorage.getItem("token");
        const { data } = await fetchSettingsDesignSystems(userId);
        setDesignSystems(data);
      } catch (error) {
        console.error("Failed to fetch design systems", error);
      } finally {
        setLoading(false);
      }
    };

    const adminId = getAdminId();
    getDesignSystems(adminId);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SettingsLayout>
      <div className='tab-content-wrapper'>
        <div className='tab-content-container dis-pr-48 dis-pl-48'>
          <div className={`plt-content-block dis-mb-32 w-100`}>
            <div className='plt-content-wrapper dis-mb-40 d-flex justify-content-between align-items-center'>
              <div className='plt-content-block-top'>
                <h6 className='plt-contents-head plt-heading-xl plt-font-color-primary'>
                  Design Systems
                </h6>
              </div>
              <div className='plt-btn-wrapper d-flex'>
                <button className='plt-btn plt-btn-primary'>
                  <span className='dss-icon dis-mr-4'>
                    {" "}
                    <PlusButtonWhiteicon />
                  </span>
                  Add system
                </button>
                <div
                  className='plt-select-dropdown-wrapper plt-filter-dropdown'
                  ref={dropdownRef}
                >
                  <button
                    className='plt-btn plt-btn-secondary dis-ml-12'
                    onClick={() => setIsDropdownOpen((prev) => !prev)}
                  >
                    <span className='dss-icon dis-mr-4'>
                      {" "}
                      <FilterButtonicon />
                    </span>
                    Filters
                  </button>

                  <Dropdown
                    isOpen={isDropdownOpen}
                    onToggle={setIsDropdownOpen}
                    customClass=''
                    trigger={isDropdownOpen}
                  >
                    <ul>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        Name
                      </li>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        URL
                      </li>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        Access
                      </li>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        Privacy
                      </li>
                    </ul>
                  </Dropdown>
                </div>
              </div>
            </div>
            <DesignsystemsTable
              data={designSystems}
              setDesignSystems={setDesignSystems}
            />
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};

export default Designsystems;
