import { getUser } from "hooks/storageUtils";

const logout = (navigate) => {
  localStorage.removeItem("token"); // Clear token
  localStorage.removeItem("user"); // Clear user
  localStorage.removeItem("globalContext"); // Clear global context
  navigate("/login");
};

const isPasswordStrong = (password) => {
  if (
    !password ||
    password.length < 6 ||
    !/[a-z]/.test(password) ||
    !/[A-Z]/.test(password) ||
    !/\d/.test(password) ||
    !/[!@#$%^&*]/.test(password)
  ) {
    return false;
  } else {
    return true;
  }
};

const isPasswordConfirmed = (password, confirmPassword) => {
  if (confirmPassword !== password) {
    return false;
  } else {
    return true;
  }
};

/**
 * Check user is domain admin
 * @returns boolean
 */
const isUserAdmin = () => {
  const user = getUser();
  return user?.current_domain?.admin_id === user?.id;
  // return JSON.stringify(user);
};
/**
 * Check user is domain admin
 * @returns boolean
 */
const isUserSuperAdmin = () => {
  const user = getUser();
  // return user?.id === process.env.REACT_APP_SUPER_ADMIN;
  return user?.id === 45;
  // return JSON.stringify(user);
};

export {
  logout,
  isPasswordStrong,
  isPasswordConfirmed,
  isUserAdmin,
  isUserSuperAdmin,
};
