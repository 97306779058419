import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EyeButton, EyeButtonOpen } from "../Icons/index";
import { login } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../layouts/AuthLayout";
import { SyncLoader } from "react-spinners";

const SignInForm = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    // Regex patterns for email and password
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{6,}$/;

    // Email validation
    if (!formData.email || !emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Password validation
    const password = formData.password;
    if (
      !password ||
      password.length < 6 ||
      !/[a-z]/.test(password) ||
      !/[A-Z]/.test(password) ||
      !/\d/.test(password) ||
      !/[!@#$%^&*]/.test(password)
    ) {
      newErrors.password = "Please enter a valid password.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
    const appEnv = process.env.REACT_APP_ENV;
    try {
      const data = await login(formData.email, formData.password);
      if (data && data.user && data.token) {
        //Set local storage for app.dessign.system
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));

        const currentUrl = window.location.origin;
        const targetDomain = `https://${data.user.current_domain?.name}.dessign.systems`;

        // Check if the current base URL is different from the target domain (excluding paths)
        if (
          data.user.current_domain?.name &&
          process.env.REACT_APP_ENV === "production" &&
          currentUrl !== targetDomain
        ) {
          window.location.href = `https://${data.user.current_domain.name}.dessign.systems/dashboard?token=${data.token}&userId=${data.user.id}`;
        } else {
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data.user));
          const token = data.token;
          navigate("/dashboard", { state: { token } });
        }
        const user = data?.user?.id;
      } else {
        toast.error(data.message || "Invalid credentials");
      }
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: null }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthLayout>
      <ToastContainer />
      <div className='register-container flex flex-column w-full h-full '>
        <div className='w-full'>
          <p className='plt-heading-3xl plt-font-color-primary dis-px-auto'>
            Welcome back
          </p>
          <p className='info-msg plt-body-md plt-font-color-secondary dis-mt-12'>
            Glad to see you again
          </p>

          <form className='flex flex-col dis-mt-40' onSubmit={handleSubmit}>
            <div className='label-input-wrapper'>
              <label
                htmlFor='email'
                className='plt-ui-text-md-medium plt-font-color-primary'
              >
                Email address
              </label>
              <input
                type='email'
                id='email'
                name='email'
                tabIndex={1}
                autoFocus={true}
                placeholder='Enter your email'
                value={formData.email}
                onChange={handleChange}
                className={`input-field-custom w-full ${errors.email ? "border-red-500" : ""
                  }`}
              />
              {errors.email && (
                <p className='error-msg plt-body-xs dis-mt-4'>{errors.email}</p>
              )}
            </div>

            <div className='label-input-wrapper dis-mt-24'>
              <div className='d-flex justify-content-between w-full'>
                <label
                  htmlFor='password'
                  className='input-label plt-font-color-primary plt-ui-text-md-medium'
                >
                  Password
                </label>
                <Link
                  to={"/forgot"}
                  className='text-[#3924C0] not-italic text-link plt-body-compact-xs '
                >
                  Forgot password
                </Link>
              </div>

              <div className='relative'>
                <input
                  type={showPassword ? "text" : "password"}
                  id='password'
                  name='password'
                  tabIndex={2}
                  placeholder='Enter your password'
                  value={formData.password}
                  onChange={handleChange}
                  className={`input-field-custom relative w-full ${errors.password ? "border-red-500" : ""
                    }`}
                />
                <span
                  onClick={togglePasswordVisibility}
                  className='absolute eye-button'
                >
                  {showPassword ? <EyeButtonOpen /> : <EyeButton />}
                </span>
              </div>
              {errors.password && (
                <p className='error-msg plt-body-xs dis-mt-4'>{errors.password}</p>
              )}
            </div>
            <button
              type='submit'
              className='primary-button-custom plt-ui-text-lg-medium plt-btn plt-btn-primary dis-mt-32 dis-mb-24'
              tabIndex={3}
            >
              {isLoading ? (
                <SyncLoader
                  color='#fff'
                  loading={true}
                  width='100%'
                  height='100%'
                />
              ) : (
                "Sign in"
              )}
            </button>
          </form>
        </div>

        <div className='d-flex w-full align-items-center justify-content-center'>
          <span className='text-desc plt-body-compact-sm font-weight-500'>
            Don’t have an Account?
          </span>{" "}
          <Link
            to='/register'
            className='text-link plt-body-compact-sm font-weight-500 dis-ml-8'
          >
            Sign up.
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignInForm;
