import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WithDesignSystemStylesheet from "./hooks/WithDesignSystemStylesheet";

import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Forgot from "./pages/ForgotPassword";
import VerifyOtp from "./pages/VerifyOtp";
import Home from "./pages/Home";
import ResetPassword from "./pages/ResetPassword";
import { DesignSystemPage } from "./pages/DesignSystems";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

import DesignSystemColors from "./pages/styles/Colors";
import DesignSystemAllTokens from "./pages/styles/AllTokens";
import DesignSystemTypography from "./pages/styles/Typography";

import DesignSystemComponents from "./pages/components/Buttons";
import DesignSystemElevation from "./pages/styles/Elevation";
import DesignSystemIconography from "./pages/styles/Iconography";
import DesignSystemAccessibility from "./pages/styles/Accessibility";
import RadioComponents from "./pages/components/Radio";
import TabsComponents from "./pages/components/Tabs";
import TogglesComponents from "./pages/components/Toggles";
import TextareaComponents from "./pages/components/Textarea";
import CheckboxComponents from "./pages/components/Checkbox";
import TagComponents from "./pages/components/Tags";
import AlertComponents from "./pages/components/Alert";
import PaginationComponents from "./pages/components/Pagination";

import DesignSystemRadius from "./pages/styles/Radius";
import Spacing from "./pages/styles/Spacing";
import Toaster from "./pages/components/Toaster";
import Inputs from "./pages/components/Inputs";
import AvatarComponents from "./pages/components/Avatar";
import AvatarGroupComponents from "./pages/components/AvatarGroup";
import Tooltip from "./pages/components/Tooltip";
import Table from "./pages/components/Table";
import Modal from "./pages/components/Modal";
import EmptyStateComponents from "pages/components/EmptyState";
import Test from "pages/Test";
import Settings from "pages/Settings";
import Subscription from "pages/settings/subscription/Subscription";
import Team from "pages/settings/team/Team";
import EmailNotification from "pages/settings/EmailNotification";
import PageNotFound from "pages/PageNotFound";
import InviteSignUpForm from "components/LoginForm/InviteSignUp";
import EditorPage from "components/DesignSystemPage/EditorPage";
import General from "pages/settings/General";
import ContentPage from "pages/content/ContentPage";
import ResourcesPage from "pages/resources/ResourcesPage";
import DesignSystemForms from "./pages/patterns/forms";
import JoinDomain from "components/LoginForm/JoinDomain";
import AdminRoute from "components/AdminRoute/AdminRoute";
import { useLocation } from "react-router-dom";
import Designsystems from "pages/settings/designsystems/Designsystems";
import GuestRoute from "components/GuestRoute/GuestRoute";
import { PrinciplesPage } from "pages/PrinciplesPage";
import { OverViewContentPage } from "pages/OverviewContentPage";
import SuperAdminRoute from "components/SuperAdminRoute/SuperAdminRoute";
import SuperAdmin from "pages/settings/SuperAdmin/SuperAdmin";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route path='/register' element={<SignUp />} />
        <Route path='/login' element={<SignIn />} />
        <Route path='/forgot' element={<Forgot />} />
        <Route path='/verify' element={<VerifyOtp />} />
        <Route path='/reset' element={<ResetPassword />} />
        <Route
          path='/invite-signup/:invite_token'
          element={<InviteSignUpForm />}
        />
        <Route
          path='/dashboard'
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/overview/getting-started'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemPage />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/public'
          element={
            <GuestRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemPage />
              </WithDesignSystemStylesheet>
            </GuestRoute>
          }
        />
        <Route
          path='/:id/overview/principles'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <PrinciplesPage />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/overview/content'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <OverViewContentPage />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/styles/colors'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemColors />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/styles/typography'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemTypography />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/styles/tokens'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemAllTokens />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/styles/elevation'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemElevation />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/styles/iconography'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemIconography />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/styles/accessibility'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemAccessibility />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/styles/radius'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemRadius />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/styles/spacing'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <Spacing />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/button'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/radio'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <RadioComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/tabs'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <TabsComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/checkbox'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <CheckboxComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/alert'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <AlertComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/avatar'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <AvatarComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/avatarGroup'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <AvatarGroupComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/toggles'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <TogglesComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/textarea'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <TextareaComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/tag'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <TagComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/toaster'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <Toaster />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/inputs'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <Inputs />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/tooltip'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <Tooltip />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/table'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <Table />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/modal'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <Modal />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/pagination'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <PaginationComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/components/emptystate'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <EmptyStateComponents />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/overview/pages/:pageId'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <EditorPage />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />{" "}
        <Route
          path='/:id/content/:pageId'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <ContentPage />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/resources/:pageId'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <ResourcesPage />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/patterns/forms'
          element={
            <ProtectedRoute>
              <WithDesignSystemStylesheet>
                <DesignSystemForms />
              </WithDesignSystemStylesheet>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/profile'
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/settings'
          element={
            <ProtectedRoute>
              <AdminRoute>
                <Settings />
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/general'
          element={
            <ProtectedRoute>
              <AdminRoute>
                <General />
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/subscriptions'
          element={
            <ProtectedRoute>
              <AdminRoute>
                <Subscription />
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/designsystems'
          element={
            <ProtectedRoute>
              <AdminRoute>
                <Designsystems />
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/teams'
          element={
            <ProtectedRoute>
              <AdminRoute>
                <Team />
              </AdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path='/:id/email-notifications'
          element={
            <ProtectedRoute>
              <EmailNotification />
            </ProtectedRoute>
          }
        />
        <Route
          path='/join-domain/:invite_token'
          element={
            <ProtectedRoute>
              <JoinDomain />
            </ProtectedRoute>
          }
        />
        <Route
          path='/test'
          element={
            <ProtectedRoute>
              <Test />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin'
          element={
            <ProtectedRoute>
              <SuperAdminRoute>
                <SuperAdmin />
              </SuperAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
