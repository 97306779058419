import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

// Upload avatar
async function upgradeSubscription(plan) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/subscriptions/change`,
      { plan },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in switch domain:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export { upgradeSubscription };
