import React from "react";
import { PERMISIONS } from "constants";
import AuthAction from "components/auth/AuthAction";
import EditButton from "components/Buttons/EditButton";
import Tooltip from "components/Tooltip";

const TeamAccessColumn = ({
  data,
  openModal,
  setSelectedData,
  designSystems,
}) => {
  return (
    <div className="d-flex ">
      {/* {JSON.stringify(data)} */}
      <div className="d-flex flex-column">
        {data?.permission
          ?.filter((perm) => perm.access_id !== 3)
          ?.map((permission, index) => (
            <div key={index} className='plt-body-md plt-font-color-secondary'>
              {
                designSystems.find((ds) => ds.id === permission.designSystemId)
                  ?.design_system_name
              }
              -
              {
                PERMISIONS.find((access) => access.value === permission.access_id)
                  ?.label
              }
            </div>
          ))}
      </div>
      <AuthAction>
        <div
          onClick={() => {
            openModal();
            setSelectedData(data);
          }}
        >
          <Tooltip position='top' arrow='btm-center' text='Edit Access'>
            <EditButton />
          </Tooltip>
        </div>
      </AuthAction>
    </div>
  );
};

export default TeamAccessColumn;
