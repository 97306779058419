import React, { useEffect, useState } from "react";
import SettingsLayout from "components/layouts/SettingsLayout";
import SubscriptionTable from "./SubscriptionTable";
import stepone from "../../../assets/images/png/stepone.png";
import stepthree from "../../../assets/images/png/stepthree.png";
import paymentone from "../../../assets/images/svg/paymentone.svg";
import paymenttwo from "../../../assets/images/svg/paymenttwo.svg";

import { WarningIconSmall } from "components/Icons";
import { DeleteButtonicon } from "components/Icons";

import "./Subscription.scss";
import PaymentMethodModal from "components/modal/PaymentMethod";
import { PlusButtonicon } from "components/Icons";
import { fetchUserSubscription } from "api";
import { useGlobalContext } from "context/GlobalContext";
import CircularLoader from "components/Common/CircularLoader/CircularLoader";
import moment from "moment";
import { getUsageCount } from "helpers/subscription";
import { usagePercentage } from "helpers/subscription";
import { upgradeSubscription } from "api";

const Subscription = () => {
  const { setLoading } = useGlobalContext();

  const [subscription, setSubscription] = useState();
  const [plan, setPlan] = useState();
  const [invoice, setInvoice] = useState();
  const [usage, setUsage] = useState();
  const [isPaymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);

  const closePaymentMethodModal = () => {
    setPaymentMethodModalOpen(false);
  };

  useEffect(() => {
    const getUserSubscription = async () => {
      try {
        setLoading(true);
        const data = await fetchUserSubscription();
        setSubscription(data.data?.subscription);
        setPlan(data.data?.plan);
        setInvoice(data.data?.invoice);
        setUsage(data.data?.usage);
      } catch (error) {
        console.error("Failed to fetch user subscription", error);
      } finally {
        setLoading(false);
      }
    };

    getUserSubscription();
  }, []);

  const usageCount = getUsageCount(usage);

  const handleUpgradeSubscription = async (plan = "premium") => {
    try {
      setLoading(true);
      const { data } = await upgradeSubscription(plan);
    } catch (error) {
      console.error("Failed to upgrade subscription", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingsLayout>
      <div className={`dis-mb-48`}>
        <div className='tab-content-wrapper'>
          <div className='tab-content-container dis-pr-48 dis-pl-48'>
            <div className='plt-content-block'>
              <div className='plt-content-wrapper dis-mb-40'>
                <div className='plt-content-block-top d-flex justify-content-between align-items-center'>
                  <h6 className='plt-contents-head plt-heading-xl plt-font-color-primary'>
                    Subscription
                  </h6>
                </div>
                {/* <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                Description
              </p> */}
              </div>
            </div>

            <div className='plt-content-wrapper dis-mb-32'>
              <div className='plt-content-block-top d-flex align-items-center dis-mb-12'>
                <h6 className='plt-contents-head plt-heading-md plt-font-color-primary font-weight-600'>
                  Plan name
                </h6>
              </div>
              <div className='plt-plan-box'>
                <div className='component-card'>
                  <div className='plan-wrapper d-flex dis-p-24'>
                    <div className='plan-detail-content d-flex align-items-center'>
                      <div className='plan-set'>
                        <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                          Plan Details
                        </p>
                        <h6 className='plt-common-card-title plt-heading-sm plt-font-color-primary dis-mt-4'>
                          {plan?.name}
                        </h6>
                      </div>
                      <div className='plan-set'>
                        <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                          Next billing date on
                        </p>
                        <h6 className='plt-common-card-title plt-heading-sm plt-font-color-primary dis-mt-4'>
                          {moment(subscription?.ends_at).format("ll")}
                        </h6>
                      </div>
                      <div className='plan-set'>
                        <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                          Billing Amount per month
                        </p>
                        <h6 className='plt-common-card-title plt-heading-sm plt-font-color-primary dis-mt-4'>
                          ${plan?.price}
                        </h6>
                      </div>
                    </div>
                    <div className='plan-detail-btn'>
                      <button
                        className='dis-ml-20 cursor-pointer plt-btn plt-btn-primary'
                        onClick={() => handleUpgradeSubscription("premium")}
                      >
                        Upgrade plan
                      </button>
                    </div>
                  </div>
                  <div className='component-details dis-p-24'>
                    <div className='plan-steps-wrapper d-flex'>
                      <div className='plan-steps-box d-flex align-items-center'>
                        <div className='plan-steps-img dis-mr-8'>
                          {/* <img src={stepone} alt='guidelinesimage' /> */}
                          <CircularLoader
                            percentage={usagePercentage(
                              usageCount,
                              plan,
                              "design_systems_per_user"
                            )}
                          />
                        </div>
                        <div className='plan-steps-content'>
                          <h6 className='plt-common-card-title plt-heading-sm plt-font-color-primary dis-mb-4'>
                            {usageCount?.design_systems_per_user || 0} of{" "}
                            {plan?.features?.find(
                              (feature) =>
                                feature.name === "design_systems_per_user"
                            )?.value || 0}
                          </h6>
                          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                            Design system created
                          </p>
                        </div>
                      </div>
                      <div className='plan-steps-box d-flex align-items-center'>
                        <div className='plan-steps-img dis-mr-8'>
                          <CircularLoader
                            percentage={usagePercentage(
                              usageCount,
                              plan,
                              "total_users"
                            )}
                          />
                        </div>
                        <div className='plan-steps-content'>
                          <h6 className='plt-common-card-title plt-heading-sm plt-font-color-primary dis-mb-4'>
                            {usageCount?.total_users || 0} of{" "}
                            {plan?.features?.find(
                              (feature) => feature.name === "total_users"
                            )?.value || 0}
                          </h6>
                          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                            Users added
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className='plt-character-des plt-body-sm dis-pt-24 d-flex align-items-center'>
                      <span className='warning-icon dis-mr-4'>
                        {" "}
                        <WarningIconSmall />
                      </span>
                      You can upgrade your plan to get higher limits and more of
                      the features you have
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* plt-content-wrapper */}
            {/* <div className='plt-content-wrapper dis-mb-32'>
              <div className='plt-content-block-top d-flex align-items-center dis-mb-12'>
                <h6 className='plt-contents-head plt-heading-md plt-font-color-primary font-weight-600'>
                  Payment details
                </h6>
              </div>
              <div className='plt-payment-box'>
                <div className='component-card dis-mb-16'>
                  <div className='payment-wrapper d-flex dis-p-24'>
                    <div className='payment-img'>
                      <img src={paymentone} alt='guidelinesimage' />
                    </div>
                    <div className='payment-detail dis-ml-12'>
                      <h6 className='plt-common-card-title plt-heading-sm plt-font-color-primary dis-mb-4'>
                        Stripe card ending in 3456
                      </h6>
                      <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                        Expiry 06/24
                      </p>
                      <h5 className='plt-badge plt-body-compact-sm font-weight-500 dis-mt-16'>
                        Default
                      </h5>
                    </div>
                    <div className='payment-delete'>
                      <DeleteButtonicon />
                    </div>
                  </div>
                </div>
                <div className='component-card'>
                  <div className='payment-wrapper d-flex dis-p-24'>
                    <div className='payment-img'>
                      <img src={paymenttwo} alt='guidelinesimage' />
                    </div>
                    <div className='payment-detail dis-ml-12'>
                      <h6 className='plt-common-card-title plt-heading-sm plt-font-color-primary dis-mb-4'>
                        Master card ending in 3456
                      </h6>
                      <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                        Expiry 06/24
                      </p>
                      <span className='plt-modal-link plt-ui-text-sm-medium dis-mt-16 cursor-pointer d-block w-fit'>
                        Set as default
                      </span>
                    </div>
                    <div className='payment-delete'>
                      <DeleteButtonicon />
                    </div>
                  </div>
                </div>
              </div>
              <button
                className='dss-btn dss-btn-ghost dss-btn-md dss-ui-text-button-text-normal dis-mt-12'
                type='button'
                onClick={() => setPaymentMethodModalOpen(true)}
              >
                <span className='dss-icon prefix-icon dis-mr-4'>
                  <PlusButtonicon />
                </span>
                Add payment method
              </button>
            </div> */}

            {/* plt-content-wrapper */}
            <div className='plt-content-wrapper dis-mb-32'>
              <div className='plt-content-block-top d-flex align-items-center dis-mb-12'>
                <h6 className='plt-contents-head plt-heading-md plt-font-color-primary font-weight-600'>
                  Invoices
                </h6>
              </div>
              <div className='plt-settings-table'>
                <SubscriptionTable data={invoice} />
                <div className='d-flex align-items-center justify-content-center dis-mt-24'>
                  <button
                    className='plt-btn plt-btn-secondary plt-btn-md dss-ui-text-button-text-normal font-weight-500 '
                    type='button'
                  >
                    Load more{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PaymentMethodModal
          isOpen={isPaymentMethodModalOpen}
          onRequestClose={closePaymentMethodModal}
          // onApply={}
          title='Add payment method'
        />
      </div>
    </SettingsLayout>
  );
};

export default Subscription;
