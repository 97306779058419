import React, { useEffect, useState } from "react";
import { PrimaryLogo } from "components/Icons/index";
import { Link } from "react-router-dom";
import UserImage from "assets/images/png/userAvatar.png";
import "./HomeHeader.scss";
import UserMenu from "components/UserMenu";
import CreateDesignSystemModal from "components/modal/CreateDesignSystemModal";
import { useGlobalContext } from "context/GlobalContext";
import { createDesignSystem } from "api";
import { getAdminId } from "hooks/storageUtils";
import { toast } from "react-toastify";
import { getUser } from "hooks/storageUtils";
import { UserIcon } from "../../Icons";
import UpgradePlanAlertModal from "components/modal/UpgradePlanAlertModal";
import { checkFeaturePermission } from "api";
import { getDomainAdminId } from "hooks/storageUtils";

const HomeHeader = () => {
  const { setDesignSystems, setLoading } = useGlobalContext();
  const [isCreateDSModalOpen, setCreateDSModalOpen] = useState(false);
  const [isUpgradePlanAlertModalOpen, setUpgradePlanAlertModalOpen] =
    useState(false);
  const [canCreateDesignSystem, setCanCreateDesignSystem] = useState(false);

  const externalLink = process.env.REACT_APP_LANDING_URL;
  const apiUrl = process.env.REACT_APP_SHEET_URL;
  const user = getUser();

  const closeCreateDSModal = () => {
    setCreateDSModalOpen(false);
  };
  const closeUpgradePlanAlertModal = () => {
    setUpgradePlanAlertModalOpen(false);
  };

  useEffect(() => {
    const checkPermission = async (adminId, permission) => {
      try {
        setLoading(true);
        const { data } = await checkFeaturePermission(adminId, permission);
        setCanCreateDesignSystem(data);
      } catch (error) {
        toast.error(error.message);
        console.error("Failed to create design system", error);
      } finally {
        setLoading(false);
      }
    };
    const adminId = getDomainAdminId();
    checkPermission(adminId, "design-systems-per-user");
  }, []);

  const handleCreateDStApply = async (request) => {
    const adminId = getAdminId();
    try {
      setLoading(true);
      const { data } = await createDesignSystem(adminId, request);
      setDesignSystems((prevState) => [...prevState, data]);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to create design system", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpgradePlantApply = () => {};
  return (
    <div className='w-full header d-flex justify-content-between '>
      <div className='main-logo logo '>
        <a
          target='_blank'
          href={externalLink}
          className='logo-link d-flex align-items-center cursor-pointer'
        >
          <span className='logo-icon'>
            <PrimaryLogo />
          </span>
          <h2 className='logo-text dis-ml-12 cursor-pointer'>Dessign</h2>
        </a>
      </div>

      <div className='header-info d-flex justify-content-center align-items-center'>
        <button
          className='home-header-button dis-mr-40  cursor-pointer plt-btn plt-btn-primary'
          onClick={() => {
            if (canCreateDesignSystem) {
              setCreateDSModalOpen(true);
            } else {
              setUpgradePlanAlertModalOpen(true);
            }
          }}
        >
          Create a new design system
        </button>
        <UserMenu
          UserImage={user?.image ? `${apiUrl}${user?.image_url}` : UserImage}
        />
      </div>
      <CreateDesignSystemModal
        isOpen={isCreateDSModalOpen}
        onRequestClose={closeCreateDSModal}
        onApply={handleCreateDStApply}
        title='Add new design system'
      />
      <UpgradePlanAlertModal
        isOpen={isUpgradePlanAlertModalOpen}
        onRequestClose={closeUpgradePlanAlertModal}
        onApply={handleUpgradePlantApply}
        title='Upgrade Plan'
      />
    </div>
  );
};

export default HomeHeader;
