import React from "react";
import CommonModal from "components/Common/CommonModal";

const UpgradePlanAlertModal = ({ isOpen, onRequestClose, onApply, title }) => {
  const handleApply = () => {};
  return (
    <CommonModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      footerClassName='plt'
      onApply={handleApply}
      onCancelText='Cancel'
      onApplyText='Upgrade'
      // bodyClassName='overflow-unset'
    >
      <div className='plt-dropdown-secondary  w-full row-gap-4 '>
        <div className='plt-select-dropdown-wrapper create-dss dis-ml-24 dis-mr-24 dis-pt-16 dis-pb-16'>
          {/* Field Name */}
          <div className='upload-font-content d-flex align-items-center justify-content-between  '>
            <div className='plt-select-dropdown-wrapper w-full'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Upgrade plan!
              </h6>
              <div className='modal-value-block w-100'>
                Please upgrade plan to use this feature
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default UpgradePlanAlertModal;
